.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #000;
    color: #fff;
    padding: 1rem;
    height: 60px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.logo {
    font-size: 1.5rem;
    font-weight: bold;
}

.navbar-right {
    display: flex;
    align-items: center;
    position: relative;
}

.username {
    font-size: 1.2rem;
    padding: 3px 20px 3px 8px;
    margin-right: -15px;
    border-radius: 20px;
    background-color: #1e1e1e;
    z-index: 1;
    cursor: pointer;
}

.profile-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #1e1e1e;
    padding: 5px;
    z-index: 2;
    cursor: pointer;
}

.dropdown-menu {
    position: absolute;
    top: 100%; 
    right: 0;
    background-color: #1e1e1e;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 10px;
    z-index: 3;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    max-height: 0; /* Start with a height of 0 */
    opacity: 0; /* Start hidden */
    transition: max-height 0.3s ease, opacity 0.3s ease; /* Add transition */
}

/* When dropdown is visible */
.dropdown-menu.open {
    max-height: 500px; /* Large enough to fit content */
    opacity: 1;
}

.dropdown-menu a, .dropdown-menu a:visited {
    color: #fff;
    padding: 10px;
    text-decoration: none;
    font-size: 1rem;
}

.dropdown-menu a:hover {
    background-color: #333;
    border-radius: 5px;
}