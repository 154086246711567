.user-sounds-container {
    background-color: #1e1e1e;
    border: 1px solid #444; /* Dark grey border */
    padding: 20px;
    border-radius: 5px;
    max-width: 300px;
}

.upload-sounds-header {
    background-color: #0056b3;
    width: 100%;
    padding: 10px 20px 10px 20px;
    margin-bottom: 40px;
    cursor: pointer;
}

.upload-sounds-with-drag {
    background-color: #7e7e7e;
}

.user-sounds-list {
    list-style-type: circle;
    padding: 0;
    margin: 0;
    max-height: 300px;
    overflow-y: auto;
}

.user-sounds-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-content: flex-start;
    padding: 10px 10px 10px 0px;
    border-top: 1px solid #333;
    border-bottom: 1px solid #333; /* Darker grey line between items */
    transition: background-color 0.2s ease;
    cursor: default;
}

.arrow-and-sound-name-container {
    display: flex;
    align-items: center;
}

.sound-name-and-play-button-container {
    display: flex;
    justify-content: space-between;
}

.sounds-panel-button {
    cursor: pointer;
}

.sound-conditions-list {
    list-style-type: none;
}

.active-sounds-container {
    border: 1px solid #444;
    border-radius: 5px;
    padding: 10px;
}

.show-hide-active-sounds-button {
    margin-top: 10px;
    margin-bottom: 10px;
}

.active-sounds-item {
    padding: 5px;
}

.active-sound-title {
    margin-left: 20px;
}

.delete-button-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
