.panel-and-samples-container {
    min-width: 20vmin;
    max-width: 60vmin;
}

.prompt-panel-container {
    padding: 20px; 
    background-color: #1e1e1e; 
    border-radius: 10px;
    gap: 10px;
    display: flex;
    flex-direction: column;
    height: fit-content;
    margin-bottom: 10px;
}

.controls-container {
    display: flex;
    justify-content: space-between;
    column-gap: 5px;
}

.add-button {
    background-color: green;
    margin-right: 5px;
}

.add-button:hover {
    background-color: rgb(0, 99, 0);
}

.generate-prompt-button {
    background-color: orangered;
}

.generate-prompt-button:hover {
    background-color: rgb(192, 51, 0);
}

.generate-image-button {
    background-color: rgb(0, 0, 179)
}

.generate-image-button:hover {
    background-color: rgb(0, 0, 114)
}

.generate-image-button:disabled {
    background-color: rgb(102, 102, 102);
}

.save-button:disabled {
    background-color: rgb(102, 102, 102);
}

.save-button.save-success {
    background-color: #28a745;
    animation: fadeOut 2s forwards;
}

@keyframes fadeOut {
    0% {
        background-color: #28a745;  /* Start with success color */
    }
    100% {
        background-color: #6a5acd;  /* Fade back to the original color */
    }
}

.active-switch-container {
    display: flex;
    align-items: center;
    padding: 1px;
}

.active-switch-container input {
    visibility: hidden;
}

.switch {
    position: relative;
    width: 55px;
    height: 31px;
    margin: 0;
    margin-right: 5px;
}

.switch-text {
    height: fit-content;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 23px;
    width: 23px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
}

input:checked + .slider {
    background-color: #2196F3;
}

input:checked + .slider:before {
    transform: translateX(26px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.sample-image {
    width: 100%;
}

.sample-image-container {
    display: flex;
    flex-direction: column;
}