.preview-window {
    position: relative;
    width: 800px;
    height: 600px;
    min-width: 800px;
    min-height: 600px;
    background-color: #2a2a2a; /* Dark background to match your theme */
    border: 2px solid #444; /* Border to make it stand out */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow effect */
    overflow: hidden;
  }

.preview-element {
    position: absolute;
    border: none;
}

.preview-element img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover; /* Ensure the image scales nicely within the container */
  }