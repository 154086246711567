.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.form-group {
    margin-bottom: 15px;
}

.modal-content {
    background-color: #383838;
    padding: 20px;
    border-radius: 10px;
    position: relative;
    font-size: large;
    display: flex;
    flex-direction: column;
    width:auto;
    min-width: 400px;
    max-width: 90%;
}

.modal-content label {
    width: fit-content;
}

.modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
}

.delete-conf-buttons-container {
    margin-top: 10px;
    display: flex;
    justify-content: space-evenly;
}

.delete-conf-buttons-container button {
    padding-left: 30px;
    padding-right: 30px;
}

.font-options {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.delete-prompt-yes-button {
    background-color: red;
}

.delete-prompt-yes-button:hover {
    background-color: rgb(124, 0, 0);
}

.name-ok-button {
    align-self: center;
    margin: 5px;
    width: 50px;
}

textarea {
    font-family: Arial, Helvetica, sans-serif;
}

.large-textarea {
    width: 90%;
    height: 100px;
}

.upload-fonts-dropzone {
    background-color: #0056b3;
    width: 100%;
    padding: 5px 10px 5px 10px;
    margin-bottom: 40px;
    cursor: pointer;
}

.fonts-dropzone-with-drag {
    background-color: #7e7e7e;
}

.alignment-buttons-container {
    display: flex;
    margin-top: 20px;
    gap: 5px;
}

.align-icon {
    cursor: pointer;
    background-color: #7e7e7e;
    padding: 2px;
}

.align-icon:hover {
    background-color: #a0a0a0;
}

.text-and-variables-container {
    display: flex;
    gap: 10px;
}

.variables-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    font-size: small;
    border: 2px solid #444;
    padding: 5px 15px 5px 5px;
    margin-bottom: 10px;
}

.show-supported-variables-clickable-text {
    text-decoration: underline;
    cursor: pointer;
}

.variables-list-heading {
    font-weight: 700;
}