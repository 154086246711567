.prompt-builder-container {
    width: fit-content;
}

.dropdown-and-new-prompt-button-container{
    display: flex;
    justify-content: space-between;
}

.new-prompt-button {
    background-color: green;
    font-size: large;
    padding: 10px;
}

.new-prompt-button:hover {
    background-color: rgb(0, 99, 0);
}

.delete-prompt-button {
    background-color: red;
    padding: 10px;
    font-size: large;
}

.delete-prompt-button:hover {
    background-color: rgb(172, 0, 0);
}

.editor-and-panel-container {
    display: flex;
    column-gap: 10px;
    margin-top: 10px;
}