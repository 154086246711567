.admin-section-divider {
    border: 1px solid #444;
    padding: 10px;
    display: flex;
    justify-content: space-between;
}

.heading-and-dropdown-container {
    margin-bottom: 10px;
}

.admin-save-button-container {
    display: flex;
    align-items: center;
    padding: 20px;
}

.save-button:disabled {
    background-color: rgb(102, 102, 102);
}

.save-button.save-success {
    background-color: #28a745;
    animation: fadeOut 2s forwards;
}

@keyframes fadeOut {
    0% {
        background-color: #28a745;  /* Start with success color */
    }
    100% {
        background-color: #6a5acd;  /* Fade back to the original color */
    }
}