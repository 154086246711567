.prompt-editor-container {
    padding: 20px; 
    background-color: #1e1e1e; 
    border-radius: 10px;
    gap: 10px;
    min-width: 50vmin;
    max-width: 80vmin;
    margin-bottom: 10px;
}

.element-container {
    padding: 10px;
    margin-bottom: 10px;
    background-color: #434343;
    border-radius: 5px;
    box-shadow: 0px 2px 6px rgba(0,0,0,0.1);
    display: flex;
    justify-content: space-between;
}

.prompt-editor-header {
    display: flex;
    justify-content: space-between;
}

.element {
    margin-right: 25px;
    padding-top: 2px;
    padding-bottom: 2px;
}

.edit-delete-buttons-container {
    width: fit-content;
    display: flex;
}

.edit-delete-buttons-container button {
    margin-left: 5px;
    margin-right: 5px;
    
}

.random-element-container {
    background-color: #6a5acd
}

.edit-button {
    background-color: rgb(30, 30, 228);
    padding-top: 4px;
    padding-bottom: 4px;
}

.edit-button:hover {
    background-color: rgb(12, 12, 160);
}

.delete-button {
    background-color: red;
    padding-top: 4px;
    padding-bottom: 4px;
}

.delete-button:hover {
    background-color: rgb(172, 0, 0);
}
