body {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
    font-weight: 700;
}

p {
    margin: 0;
    font-weight: 400;
}

.prompt-page {
    display: flex;
    justify-content: space-between;
}

.layout-page {
    display: flex;
    justify-content: space-between;
}
