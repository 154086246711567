.layout-settings-container {
    background-color: #1e1e1e;
    border: 1px solid #444; /* Dark grey border */
    padding: 20px;
    border-radius: 5px;
    max-width: 300px;
    min-width: 300px;
}

.hide-settings-text {
    cursor: pointer;
    text-decoration: underline;
}

.layout-settings-header {
    display: flex;
    justify-content: space-between;
}

.active-layouts-container {
    border: 1px solid #444;
    border-radius: 5px;
    padding: 10px;
}

.show-hide-active-layouts-button {
    margin-top: 10px;
    margin-bottom: 10px;
}