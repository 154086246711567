.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.new-prompt-modal .modal-content {
    background-color: #383838;
    padding: 20px;
    border-radius: 10px;
    width: 500px;
    position: relative;
}

.modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
}

.prompt-name-input {
    width: 90%;
}

.form-group {
    margin-bottom: 15px;
}

.create-button, .cancel-button {
    margin-right: 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.create-button {
    background-color: #28a745;
    color: white;
}

.create-button:hover {
    background-color: #1b7530;
}

.cancel-button {
    background-color: #dc3545;
    color: white;
}

.cancel-button:hover {
    background-color: #9b2732;
    color: white;
}
