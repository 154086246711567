.home-page {
    display: flex;
    flex-direction: column;
    height: fit-content;
    width: 100%;
    justify-content: space-between;
}

.hero {
    background-color: #121212;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    height: 30vh;
    color: white; /* Text color to contrast with the background */
}

.image-carousel-container {
    background-color: black;
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(512px, 1fr)); 
    justify-items: center;
    align-items: center;
    padding: 16px;
    height: fit-content;
}


.image-container {
    position: relative;
    width: 512px;
    height: 512px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding: 20px;
}

.carousel-image {
    position: absolute;
    width: 100%;
    height: auto;
    opacity: 0;
    transition: opacity 1s ease-in-out;
}

@media (max-width: 568px) {
    .image-carousel-container {
        display: flex;
        flex-direction: column;
    }

    .image-container {
        width: 90vw; /* Scale down to 90% of screen width */
        height: 90vw; /* Adjust height proportionally */
        max-width: 512px;
        overflow: hidden;
        padding: 0;
    }

    .carousel-image {
        width: 100%; /* Scale image to fit the container */
        height: auto; /* Keep aspect ratio */
    }
}

.carousel-image.visible {
    opacity: 1;
}

.carousel-image.hidden {
    opacity: 0;
}

.homepage-footer {
    display: flex;
    justify-content: space-around;
    align-content: center;
    background-color: #121212;
    color: white;
}

.homepage-login-button {
    display: flex;
    align-items: center;
    padding: 10px;
    font-size: large;
    font-weight: 700;
    height: fit-content;
    width: fit-content;
    gap: 8px;
}

.login-button-twitch-logo {
    max-width: 30px;
    max-height: 30px;
}